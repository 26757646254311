import React                   from 'react';
import Breakpoint              from 'react-socks';
import { Container, Col, Row } from 'react-grid-system';
import styled                  from '@emotion/styled';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import ResponsiveIFrame  from '@interness/web-core/src/components/structure/iFrame/iFrame';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;


const IndexPage = (props) => {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Bredow</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <p>Juwelier Bredow in Versmold, gelegen in der münsterländischen Bucht in Ostwestfalen, ist seit mehr als 30 Jahren ein Begriff für außergewöhnlichem Schmuck, feine Uhren und exklusive Trauringe und Eheringe. 
              In unseren moderneren Geschäftsräumen, in der Berlinerstr. 3, möchten wir Ihnen ein wunderbares Einkaufserlebnis ermöglichen.</p>
            <p>Unser fein ausgewähltes Sortiment von außergewöhnlichem Schmuck, feinen Uhren und exklusiven Trauringe und Eheringen verbindet den klassischen und traditionellen Juwelier, 
              sowie die angesagten Trendsetter-Marken. Wir legen größten Wert auf persönliche Beratung und individuellen Service. </p>
            <p>Gerne widmen wir Ihnen unsere Aufmerksamkeit für Ihre individuellen Wünsche bei Uhren und Schmuck. Wir nehmen uns die Zeit für Sie, damit Sie Ihre ganz persönlichen Trauringe und Eheringe finden.  
              Freundliche und fachlich kompetente Beratung sind für uns eine Selbstverständlichkeit.</p>
              <p>Wir bieten Ihnen perfekten Service uns erstklassige Reparaturen.
              Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. 
              </p>
            <p>Genießen Sie eine angenehme Atmosphäre und persönliche Beratung bei Ihrem Juwelier Bredow.<br/> Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an </p>
            <p>Bis bald in Versmold, <br/>Ihr Team von Juwelier Bredow</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unser Angebot</Heading>
          <Spacer height={20}/>
          <Display/>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>
            Professioneller Service gehört seit jeher zu den wesentlichen Tugenden unseres Hauses. Wir reparieren Ihre Armbanduhren,  Ihre Großuhren, führen Goldschmiedereparaturen aus und kaufen Ihr Altgold an.
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h2>Armbanduhren</h2>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h2>Goldschmiedereparaturen</h2>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu aufziehen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h2>Großuhren</h2>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                <h2>Altgold- und Altsilberankauf</h2>
                <List>
                  <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                  <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</li>
                  <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
                  <li>Altgoldankauf ist Vertrauenssache</li>
                </List>
              </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Virtueller Rundgang</Heading>
          <ResponsiveIFrame src='https://www.google.com/maps/embed?pb=!4v1561543030041!6m8!1m7!1sCAoSLEFGMVFpcE9peGdPYUxHaTFkR3ZZc1JXNGFoZ2RPRC10Rl82WXRRZzdKWVVQ!2m2!1d52.04000746887618!2d8.156459528721598!3f300!4f0!5f0.7820865974627469' title='VirtuellerRundgang' />
        </section>
        <Spacer height={20}/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default IndexPage;
